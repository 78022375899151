body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loginform{
	padding: 20px;
}
.lgin{
	background-color: #2284775c;
	border: 1px solid #828282;
	border-radius: 5px;
    padding: 40px 10px;
	color: #000000;
}

.loginform .input-group-text {
    /* display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap; */
    background-color: transparent;
	border: none;
    /* border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius); */
}

.loginform .form-control {
    border: none;
	outline: none !important;
	transition:none
}
.loginform .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.successorder {
	align-items: center;
	height: 100vh;
	text-align: center;
  }
  
  .successorder img {
	max-width: 300px;
	margin: auto;
  }
  

.btn-xs {
    font-size: 11px !important;
    padding: 1px 5px !important;
	margin-left: 5px !important;
}
.rdbtn1{
	margin-right: 5px;
}

.wh-100{
	width: 100vh !important;
	height: 100vh;
}
.MuiTypography-root{
	margin-bottom: 1px;
	margin-right: 5px;
}


.radio-inline{
	padding: 25px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 10px;
}


		.MuiSvgIcon-root {
			fill: currentColor;
			width: 1em;
			height: 1em;
			display: inline-block;
			font-size: 1.5rem;
			transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			flex-shrink: 0;
			user-select: none;
		}

		.MuiSvgIcon-colorPrimary {
			color: #3f51b5;
		}

		.MuiSvgIcon-colorSecondary {
			color: #f50057;
		}

		.MuiSvgIcon-colorAction {
			color: rgba(0, 0, 0, 0.54);
		}

		.MuiSvgIcon-colorError {
			color: #f44336;
		}

		.MuiSvgIcon-colorDisabled {
			color: rgba(0, 0, 0, 0.26);
		}

		.MuiSvgIcon-fontSizeInherit {
			font-size: inherit;
		}

		.MuiSvgIcon-fontSizeSmall {
			font-size: 1.25rem;
		}

		.MuiSvgIcon-fontSizeLarge {
			font-size: 2.1875rem;
		}


  
		@-webkit-keyframes mui-auto-fill {}

		@-webkit-keyframes mui-auto-fill-cancel {}

		.MuiInputBase-root {
			color: rgba(0, 0, 0, 0.87);
			cursor: text;
			display: inline-flex;
			position: relative;
			font-size: 1rem;
			box-sizing: border-box;
			align-items: center;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			font-weight: 400;
			line-height: 1.1876em;
			letter-spacing: 0.00938em;
		}

		.MuiInputBase-root.Mui-disabled {
			color: rgba(0, 0, 0, 0.38);
			cursor: default;
		}

		.MuiInputBase-multiline {
			padding: 6px 0 7px;
		}

		.MuiInputBase-multiline.MuiInputBase-marginDense {
			padding-top: 3px;
		}

		.MuiInputBase-fullWidth {
			width: 100%;
		}

		.MuiInputBase-input {
			font: inherit;
			color: currentColor;
			width: 100%;
			border: 0;
			height: 1.1876em;
			margin: 0;
			display: block;
			padding: 6px 0 7px;
			min-width: 0;
			background: none;
			box-sizing: content-box;
			animation-name: mui-auto-fill-cancel;
			letter-spacing: inherit;
			animation-duration: 10ms;
			-webkit-tap-highlight-color: transparent;
		}

		.MuiInputBase-input::-webkit-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input::-moz-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input:-ms-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input::-ms-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input:focus {
			outline: 0;
		}

		.MuiInputBase-input:invalid {
			box-shadow: none;
		}

		.MuiInputBase-input::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		.MuiInputBase-input.Mui-disabled {
			opacity: 1;
		}

		.MuiInputBase-input:-webkit-autofill {
			animation-name: mui-auto-fill;
			animation-duration: 5000s;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-moz-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:-ms-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-ms-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-webkit-input-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-moz-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus:-ms-input-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-ms-input-placeholder {
			opacity: 0.42;
		}

		.MuiInputBase-inputMarginDense {
			padding-top: 3px;
		}

		.MuiInputBase-inputMultiline {
			height: auto;
			resize: none;
			padding: 0;
		}

		.MuiInputBase-inputTypeSearch {
			-moz-appearance: textfield;
			-webkit-appearance: textfield;
		}



		.MuiInput-root {
			position: relative;
		}

		label+.MuiInput-formControl {
			margin-top: 16px;
		}

		.MuiInput-colorSecondary.MuiInput-underline:after {
			border-bottom-color: #f50057;
		}

		.MuiInput-underline:after {
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			position: absolute;
			transform: scaleX(0);
			transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
			border-bottom: 2px solid #3f51b5;
			pointer-events: none;
		}

		.MuiInput-underline.Mui-focused:after {
			transform: scaleX(1);
		}

		.MuiInput-underline.Mui-error:after {
			transform: scaleX(1);
			border-bottom-color: #f44336;
		}

		.MuiInput-underline:before {
			left: 0;
			right: 0;
			bottom: 0;
			content: "\00a0";
			position: absolute;
			transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			border-bottom: 1px solid rgba(0, 0, 0, 0.42);
			pointer-events: none;
		}

		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: 2px solid rgba(0, 0, 0, 0.87);
		}

		.MuiInput-underline.Mui-disabled:before {
			border-bottom-style: dotted;
		}

		@media (hover: none) {
			.MuiInput-underline:hover:not(.Mui-disabled):before {
				border-bottom: 1px solid rgba(0, 0, 0, 0.42);
			}
		}


    .main-area {
      background-color: transparent;
      overflow: hidden;
      width: 100%;
      min-width: 393px;
  }
    .resp-r{
      margin-left: 80px;
    }

		.MuiFormControl-root {
			border: 0;
			margin: 0;
			display: inline-flex;
			padding: 0;
			position: relative;
			min-width: 0;
			flex-direction: column;
			vertical-align: top;
		}

		.MuiFormControl-marginNormal {
			margin-top: 16px;
			margin-bottom: 8px;
		}

		.MuiFormControl-marginDense {
			margin-top: 8px;
			margin-bottom: 4px;
		}

		.MuiFormControl-fullWidth {
			width: 100%;
		}

.profileContainer{
	text-align: center;
}

.profileContainerChild{
	background: #f44336;
	margin: 10px 15px;
	padding-top: 15px;
	color: #ffffff;
	padding-left: 10px;
	border-radius: 5px;
	text-align: left;
}
.sideNav-options{
	text-decoration: none;
	cursor: pointer;
}

.profileChildRight {
	background: #ffffff;
    color: #f44336;
    margin: 2px 8px;
    border-radius: 5px;
    padding: 2px;
    display: flex;
}

.profileChildRightbox1{
	width: 40%;
}
.profileChildRightbox2{
	width: 60%;
}

.profileChildRight a {
	font-size: 10px;
	color: #f44336;
	text-decoration: none;
	/* padding-right: 10px; */
}

.profileContainerChild h6{
font-size: 14px;
margin-bottom: 2px;
}
.profileContainerChild p{
font-size: 14px;
}

.profileContainer p{
	margin-bottom: 2px;
	/* color: #f44336; */
}
.profileContainer a{
	margin-bottom: 2px;
	color: #f44336;
	text-decoration: none;
}

.logoutBox {

	background: #f7d8d6;
	margin: 10px 15px;
	/* padding-top: 15px; */

	padding: 10px;
}
.logoutBox img{

width: 25px;
}

.logoutBox p{
	margin-bottom: 2px;
	color: #f44336;
	display: inline;
	
    padding-left: 20px;

}

.trList{
	text-align: left;
	font-size: 12px;
}
.trList2{
	text-align: left;
	font-size: 12px;
	margin-bottom: 1px;
}

.userList{
	border-bottom: 1px solid black;
	padding-bottom: 5px;
	padding-top: 8px;
}
.propayBox{
	margin: 20px;
	background: #ffffff;
	padding: 15px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .06);
}
.profileFirstBox{
	min-height: 100px;
}
.profileFirstBox p{
margin-bottom: 6px;
}
.profileFirstBox a{
margin-bottom: 1px;
background: green;
color: #ffffff;
padding: 10px;
text-decoration: none;
border-radius: 5px;
margin-top: 15px;
}

.sendbtn{
	color: #ffffff;
    background-color: green;
    border-color: green;
	padding: 5px;
    font-size: 11px;
}

.wibtn{
	margin-top: 20px;
	/* background: #dc3545 !important; */
}
.wibtn a{
	background: #dc3545 !important;
    width: 100% !important;
    display: block;

}

.box2{
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	text-align: left;
}

.box2 p{
	margin-bottom: 1px;
	font-size: 12px;
}
.box2 h6{
	margin-bottom: 1px;
}

.box2 img {
	margin-top: 8px;
	margin-left: 17px;
}

.profileChildRight img {
	width: 20px;
}

.proimg{
	position: relative;
}

.proimgv{
	position: absolute;
	top: 0;
	left: 0;
}

.filterBox{
	position: fixed;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .06);
    height: 60px;
    padding: 0 10px;
    top: 60px;
	max-width: 393px;
    width: 100%;
    z-index: 2;
    align-items: center;
    display: flex;
    background: #ffffff;
}

.flbv{
	position: absolute;
	right: 10px;

	top: -14px;
}

.flbvcon{
	position: relative;
	width: 100%;
}

.btnfil{

background: rgba(47, 47, 240, 0.713) !important;
color: #ffffff !important;

}

.btnfilActive{

	background: rgba(0, 0, 247, 0.713) !important;
	color: #ffffff !important;
}
.btnfild{

	background: rgba(5, 133, 5, 0.713) !important;
	color: #ffffff !important;
}
.socialmain {
    margin-top: 27px;
}

.socialmain a{
    width: 40px;
    height: 40px;

    display: inline-block;
 
    border: 1px solid rgb(4, 0, 255);
    border-radius: 50%;
	margin-left: 5px;
	text-align: center;
}
.socialmain img{
	width: 81%;
    padding: 4px;
	margin-top: 5px;

/* padding: 4px; */
}

.messagebox1 {
	padding: 10px 20px 10px 20px;

    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    display: flex;
	background: red;
	color: #ffffff;
}
.messagebox2 {
	padding: 10px 20px 10px 20px;

    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    display: flex;
	background: rgb(16, 178, 35);
	color: #ffffff;
}

.messageboxtop {
	padding-top: 20px;
   
}

.messagebox1 .collapseCard-text {
    color: #ffffff;
    font-size: .8em;
    font-weight: 500;
    opacity: .9;
}
.messagebox2 .collapseCard-text {
    color: #ffffff;
    font-size: .8em;
    font-weight: 500;
    opacity: .9;
}

.btbox{
	height: 40px;
}

/* #runningBattle{
	border-radius: 5px;
	box-shadow: 3px 1px 14px #aaaaaa;

}
#openBattle{
	border-radius: 5px;
	box-shadow: 3px 1px 14px #aaaaaa;
	margin-bottom: 10px;
} */


.animated-box {
	width: 100px;
	height: 100px;
	background-color: blue;
	animation: changeColor 4s infinite;
  }
  
  /* Keyframes for changeColor animation */
  @keyframes changeColor {
	0% {
	  background-color: blue;
	}
	50% {
	  background-color: green;
	}
	100% {
	  background-color: blue;
	}
  }